import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

function Team() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[100],
          }}
        >
          Box 1
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[200],
          }}
        >
          Box 2
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[300],
          }}
        >
          Box 3
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[400],
          }}
        >
          Box 4
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[500],
          }}
        >
          Box 5
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[600],
          }}
        >
          Box 6
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[700],
          }}
        >
          Box 7
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[800],
          }}
        >
          Box 8
        </div>
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            backgroundColor: colors.primary[900],
          }}
        >
          Box 9
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[100],
        }}
      >
        Box 1
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[200],
        }}
      >
        Box 2
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[300],
        }}
      >
        Box 3
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[400],
        }}
      >
        Box 4
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[500],
        }}
      >
        Box 5
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[600],
        }}
      >
        Box 6
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[700],
        }}
      >
        Box 7
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[800],
        }}
      >
        Box 8
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.grey[900],
        }}
      >
        Box 9
      </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[100],
        }}
      >
        Box 1
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[200],
        }}
      >
        Box 2
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[300],
        }}
      >
        Box 3
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[400],
        }}
      >
        Box 4
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[500],
        }}
      >
        Box 5
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[600],
        }}
      >
        Box 6
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[700],
        }}
      >
        Box 7
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[800],
        }}
      >
        Box 8
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.blueAccent[900],
        }}
      >
        Box 9
      </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[100],
        }}
      >
        Box 1
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[200],
        }}
      >
        Box 2
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[300],
        }}
      >
        Box 3
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[400],
        }}
      >
        Box 4
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[500],
        }}
      >
        Box 5
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[600],
        }}
      >
        Box 6
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[700],
        }}
      >
        Box 7
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[800],
        }}
      >
        Box 8
      </div>
      <div
        style={{
          width: "100px",
          height: "100px",
          border: "1px solid black",
          backgroundColor: colors.purpleAccent[900],
        }}
      >
        Box 9
      </div>
      </div>
    </div>
  );
}

export default Team;
