import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Oval } from "react-loader-spinner";

import { ColorModeContext, useMode } from "./theme";
import { AuthContext } from "./context/AuthContext";
import { auth } from "./firebase_setup/firebase";
import MainLayout from "./scenes/global/MainLayout";
import PublicLanding from "./scenes/landing/Public";
import LoginPage from "./scenes/landing/auth/LoginPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DataDeletionRequestPage from "./pages/DataDeletionRequestPage";
import CallCenterSolutionPage from "./pages/CallCenterSolutionPage";

function App() {
  const [theme, colorMode] = useMode();
  const { isLoading, userToken } = useContext(AuthContext);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setShowLoader(false);
    }
  }, [isLoading]);

  if (isLoading || showLoader) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={userToken ? <Navigate to="/app" /> : <PublicLanding />} />
            <Route path="/products" element={<CallCenterSolutionPage />} />
            <Route path="/call-center-solution" element={<CallCenterSolutionPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/data-deletion-request" element={<DataDeletionRequestPage />} />
            <Route path="/login" element={userToken ? <Navigate to="/app" /> : <LoginPage />} />
            <Route path="/app/*" element={userToken ? <MainLayout /> : <Navigate to="/login" />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Router>
  );
}

const LoadingSpinner = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(255, 255, 255, 0.8)",
      zIndex: 1000,
    }}
  >
    <Oval color="#00BFFF" height={80} width={80} />
  </div>
);

export default App;
