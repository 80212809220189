import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const requestForToken = async (uid) => {
  try {
    const messaging = getMessaging(app);
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_vapidKey });

    if (currentToken) {
      const tokenRef = doc(db, "users", uid);
      const userSnapshot = await getDoc(tokenRef);
      const userData = userSnapshot.data() || {};

      const updatedUserData = {
        ...userData,
        currentToken,
      };

      await setDoc(tokenRef, updatedUserData);

      try {
        await updateProfile(auth.currentUser, { fcmToken: currentToken });
      } catch (error) {
        console.error("Error updating user profile:", error);
      }

      return currentToken;
    } else {
      console.warn("No registration token available. Request permission to generate one.");
      return null;
    }
  } catch (err) {
    console.error("An error occurred while requesting the token:", err);
    throw err;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    const messaging = getMessaging(app);
    onMessage(messaging, (payload) => {
      console.log("Received message:", payload);
      resolve(payload);
    });
  });
