import React, { useState, useEffect, useCallback } from "react";

const NotificationReq = () => {
  const [notificationRequested, setNotificationRequested] = useState(true);
  const [notificationHide, setNotificationHide] = useState(false);

  const checkNotificationPermission = useCallback(() => {
    if (Notification.permission === "granted") {
      setNotificationHide(true);
    }
  }, []);

  const checkNotificationSupport = useCallback(() => {
    if (!("Notification" in window)) {
      console.warn("This browser does not support desktop notifications");
      setNotificationHide(true);
    } else {
      checkNotificationPermission();
    }
  }, [checkNotificationPermission]);

  useEffect(() => {
    checkNotificationSupport();
  }, [checkNotificationSupport]);

  const handleNotificationRequest = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setNotificationHide(true);
      }
    });
    setNotificationRequested(false);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {!notificationHide && notificationRequested && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#3498db",
            color: "#fff",
            // position: "absolute",
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          <div>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-hidden="true"
              onClick={() => setNotificationRequested(false)}
              style={{ fontSize: "15px", padding:"0 10px", marginRight: "20px" }}
            >
              X
            </button>
          </div>
          <div className="alert alert-info alert-dismissible">
            <span style={{ fontSize: "14px" }}>
              Helpline needs your permission to{" "}
            </span>
            <span
              className="request-desktop-notifications"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={handleNotificationRequest}
            >
              enable desktop notifications.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationReq;
